import React from 'react'
import slugify from "slugify"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const FAQPage = ({ data }) => {
  const { page } = data;

  return (   
    <Layout>
      <SEO title={page.seo_title} description={page.seo_description} />

      <div className="relative bg-white">
        <div className="max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="relative space-y-4">
              <div className="sticky top-8">
                <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
                  { page.heading }
                </h2>
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  { page.description }
                </p>
                <p className="mt-6 font-medium text-base text-gray-700">
                  Jump to a question:
                </p>         
                <Sidenav faqs={page.faqs} />
              </div>              
            </div>

            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                { page.faqs.map((faq, index) => {
                  return (
                    <div key={`question-${index}`} id={slugify(faq.question, {lower: true})} className="space-y-2 focus:bg-red-100">
                      <dt className="text-lg leading-6 font-medium text-gray-900">
                        { faq.question }
                      </dt>
                      <dd className="text-base leading-6 text-gray-500">
                        { faq.answer }
                      </dd>
                    </div>
                  )
                })}              
              </dl>
            </div>
          </div>
        </div>
      </div>
    </Layout>    
  )
}

export default FAQPage

export const query = graphql`
  query {
    page: sanityFaqPage {
      seo_title
      seo_description
      heading
      description
      faqs {
        question
        answer
      }
    }
  }
`

const Sidenav = ({ faqs }) => {
  return (    
    <nav className="space-y-1" aria-label="Sidebar">
      {faqs.map((faq, index) => (
        <Link key={`faq-link-${index}`} to={`#${slugify(faq.question, {lower: true})}`} className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 flex items-center px-0 py-2 text-sm font-medium rounded-md">
          <span className="truncate">
            {faq.question}
          </span>
        </Link>
      ))}     
    </nav>
  )
}